<template>
  <section-headed-description
    :title="translations.certificatesTitle"
    :description="documentToHtmlString(translations.certificatesDescription)"
    :icon="icon"
  />
</template>

<script>
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
  import SectionHeadedDescription from '@/views/sections/reusable/HeadedDescription'
  import { STORE_NAMESPACE_CERTIFICATES } from '@/views/certificates/Index.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'SectionCertificatesAboutManagementSystems',
    components: {
      SectionHeadedDescription,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_CERTIFICATES, [
        'translations',
      ]),
    },
    methods: {
      documentToHtmlString,
    },
    data: () => ({
      icon: 'mdi-certificate',
    }),
  }
</script>
